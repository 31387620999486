import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { post } from '../../utils/api';
import { QuoteType } from '../Quotation/quotationReducer';

import TextInputValidation from '../../components/EditableInputs/TextInputValidation';
import NumberInputValidation from '../../components/EditableInputs/NumberIntputValidation';
import styles from '../../neumorpism.module.scss';
import { BusinessDetailsType, BusinessDetailsHeardFromType, fileLT2MBValidator, QuoteConfigPropTypes } from './BusinessDetailsReducer';
import { fetchBusinessDetails, fetchSpecialities } from './BusinessDetailsAPI';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';
import InputUpload from '../../components/EditableInputs/InputUpload';
import Select from 'react-select';
import MobileLayout from '../../utils/MobileLayout';
import DesktopLayout from '../../utils/DesktopLayout';
import responsive from '../../responsive.module.scss';
import bstyles from '../BusinessDetails/BusinessDetails.module.scss';
import Validator from '../../utils/Validator';
import { getFieldLabel, validateModelFields } from '../../utils/utils';

interface PropsTemplate {
  business_details_validation?: any;
};

const BusinessDetails: React.FC<PropsTemplate> = (props) => {
  const { id, jwt_token }: any = useParams();
  const history = useHistory();
  const [businessValues, setBusinessValues] = useState({} as BusinessDetailsType);
  const [quote, setQuote] = useState({} as QuoteType);
  const [tradingAs, setTradingAs] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [idRegNr, setIdRegNr] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [disciplineApi, setDisciplineApi] = useState([]);
  const [discipline, setDiscipline] = useState('');
  const [postalAddress, setPostalAddress] = useState('');
  const [postalAddress2, setPostalAddress2] = useState('');
  const [postalAddress3, setPostalAddress3] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [streetAddress3, setStreetAddress3] = useState('');
  const [streetCode, setStreetCode] = useState('');
  const [telNr, setTelNr] = useState(0);
  const [cellNr, setCellNr] = useState(0);
  const [faxNr, setFaxNr] = useState('');
  const [emailDebitOrder, setEmailDebitOrder] = useState('');
  const [emailAccounts, setEmailAccounts] = useState('');
  const [emailNewsletter, setEmailsNewsletter] = useState('');
  const [emailPracticeManager, setEmailPracticeManager] = useState('');
  const [creditControlName, setCreditControlName] = useState('');
  const [creditControlEmail, setCreditControlEmail] = useState('');
  const [businessDetailsLoading, setBusinessDetailsLoading] = useState(false);
  const [IOCellNo, setIOCellNo] = useState(0);
  const [practiceNr, setPracticeNr] = useState('');
  const [hpcsaNr, setHpcsaNr] = useState('');
  const [mhgUsername, setMHGUsername] = useState('');
  const [mhgPassword, setMHGPassword] = useState('');
  const [vatNr, setVatNr] = useState('');
  const [mhgUsernameErr, setMHGUsernameErr] = useState('');
  const [mhgPasswordErr, setMHGPasswordErr] = useState('');
  const [businessNameErr, setBusinessNameErr] = useState('');
  const [tradingAsErr, setTradingAsErr] = useState('');
  const [idRegNrErr, setIdRegNrErr] = useState('');
  const [contactPersonErr, setContactPersonErr] = useState('');
  const [disciplineErr, setDisciplineErr] = useState('');
  const [postalAddressErr, setPostalAddressErr] = useState('');
  const [postalAddressErr2, setPostalAddressErr2] = useState('');
  const [postalAddressErr3, setPostalAddressErr3] = useState('');
  const [postalCodeErr, setPostalCodeErr] = useState('');
  const [streetAddressErr, setStreetAddressErr] = useState('');
  const [streetAddressErr2, setStreetAddressErr2] = useState('');
  const [streetAddressErr3, setStreetAddressErr3] = useState('');
  const [streetCodeErr, setStreetCodeErr] = useState('');
  const [telNrErr, setTelNrErr] = useState('');
  const [cellNrErr, setCellNrErr] = useState('');
  const [faxNrErr, setFaxNrErr] = useState('');
  const [emailDebitOrderErr, setEmailDebitOrderErr] = useState('');
  const [emailAccountsErr, setEmailAccountsErr] = useState('');
  const [emailNewsletterErr, setEmailsNewsletterErr] = useState('');
  const [creditControlNameErr, setCreditControlNameErr] = useState('');
  const [creditControlEmailErr, setCreditControlEmailErr] = useState('');
  const [practiceNrErr, setPracticeNrErr] = useState('');
  const [hpcsaNrErr, setHpcsaNrErr] = useState('');
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [acceptContact, setAcceptContact] = useState('');
  const [acceptContactErr, setAcceptContactErr] = useState('');
  const [seenSocialMediaErr, setSeenSocialMediaErr] = useState('');
  const [emailPracticeManagerErr, setEmailPracticeManagerErr] = useState('');
  const [IOCellNoErr, setIOCellNoErr] = useState('');
  const [idDocument, setIdDocument] = useState('');
  const [idDocumentErr, setIdDocumentErr] = useState('');
  const [companyDocument, setCompanyDocument] = useState('');
  const [companyDocumentErr, setCompanyDocumentErr] = useState('');
  const [bankDetails, setBankDetails] = useState('');
  const [bankDetailsErr, setBankDetailsErr] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [heardFrom, setHeardFrom] = useState<BusinessDetailsHeardFromType[]>([])
  const [seenSocialMedia, setSeenSocialMedia] = useState('');
  const [debitorderBool, setDebitOrderBool] = useState(false);
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [accountType, setAccountType] = useState('');
  const [dateOfDebitOrder, setDateOfDebitOrder] = useState('');
  const [debitorderBoolError, setDebitOrderBoolError] = useState('');
  const [bankError, setBankError] = useState('');
  const [bankBranchError, setBankBranchError] = useState('');
  const [branchCodeError, setBranchCodeError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [accountNoError, setAccountNoError] = useState('');
  const [accountTypeError, setAccountTypeError] = useState('');
  const [dateOfDebitOrderError, setDateOfDebitOrderError] = useState('');
  const [defaultFileLoaded, setDefaultFileLoadedState] = useState({ iddoc: false, companydoc: false, bankdoc: false });
  const [quoteConfig, setQuoteConfig] = useState({} as QuoteConfigPropTypes);
  const [heardFromErr, setHeardFromErr] = useState('');
  const [vatNrErr, setVatNrErr] = useState('');
  const [businessTypeErr, setBusinessTypeErr] = useState('');
  const [isTokenValid, setIsTokenValid] = useState({"isValid": "", "isTemp": ""});
  const [businessDetailsValidation] = useState(props.business_details_validation);
 
  const HeardFromOptions = [
    { value: '1', label: 'Google' },
    { value: '2', label: 'Social Media Platform' },
    { value: '3', label: 'Publication - Magazine/Advert' },
    { value: '4', label: 'Marketing Emails' },
    { value: '5', label: 'Word of Mounth - Friend/Colleague' },
    { value: '6', label: 'Sales person - Cold Calling' },
    { value: '7', label: 'Events -Expos/Congress/Golf gathering' },
    { value: '8', label: 'Other' },
  ];

  useEffect(() => {
    if (id !== undefined) {
      const validateToken = async () => {
        const resp = await post(`/quotation/validate_token`, {jwt_token, id});

        if (resp.ok) {
          setIsTokenValid(await resp.json());
        }
      }
      validateToken();
    }
  },[id, jwt_token]);

  const BusinessDetailsUpdate = async (field: any, value: any) => {
    const resp = await post(`/quotation/business_details_update/${quote.id}`, {
      field,
      value
    });

    if (resp.ok) {
      return true;
    } else {
      toast.error(resp.statusText + '. Contact GoodX for further assistance.');
      return false;
    }
  };

  const marketerSubmitSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: number,
    cellNr: number,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: number,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch: string,
    branchCode: string,
    accountName:string,
    accountNo:string,
    accountType:string,
    dateOfDebitOrder:string,
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/marketer_fill_in_details', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool:debitorderBool,
      bank:bank,
      branch:branch,
      branchCode:branchCode,
      accountName:accountName,
      accountNo:accountNo,
      accountType:accountType,
      dateOfDebitOrder:dateOfDebitOrder
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      history.push({ pathname: `/` });
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const BusinessDetailsSave = async (
    id: string,
    businessName: string,
    tradingAs: string,
    idRegNr: string,
    contactPerson: string,
    discipline: string,
    postalAddress: string,
    postalAddress2: string,
    postalAddress3: string,
    postalCode: string,
    streetAddress: string,
    streetAddress2: string,
    streetAddress3: string,
    streetCode: string,
    telNr: number,
    cellNr: number,
    faxNr: string,
    emailDebitOrder: string,
    emailAccounts: string,
    emailNewsletter: string,
    creditControlName: string,
    creditControlEmail: string,
    practiceNr: string,
    hpcsaNr: string,
    mhgUsername: string,
    mhgPassword: string,
    vatNr: string,
    acceptContact: string,
    agreementCheck: boolean,
    idDocument: string,
    companyDocument: string,
    bankDetails: string,
    IOCellNo: number,
    emailPracticeManager: string,
    businessType: string,
    heardFrom: string,
    socialMediaPlatforms: string,
    debitorderBool: boolean,
    bank: string,
    branch: string,
    branchCode: string,
    accountName:string,
    accountNo:string,
    accountType:string,
    dateOfDebitOrder:string,
    allowedToSign : boolean,
    signedOnBehalfOf:string,
    dateSigned:Date,
    signedName:string,
    signedSurname:string,
    signedRole:string,
    signedPlace:string,
    signedCell:string,
    signedEmail:string
  ) => {
    setIsLoading(true);
    const resp = await post('/quotation/business_details_submit', {
      id: id,
      businessName: businessName,
      tradingAs: tradingAs,
      idRegNr: idRegNr,
      contactPerson: contactPerson,
      discipline: discipline,
      postalAddress: postalAddress,
      postalAddress2: postalAddress2,
      postalAddress3: postalAddress3,
      postalCode: postalCode,
      streetAddress: streetAddress,
      streetAddress2: streetAddress2,
      streetAddress3: streetAddress3,
      streetCode: streetCode,
      telNr: telNr,
      cellNr: cellNr,
      faxNr: faxNr,
      emailDebitOrder: emailDebitOrder,
      emailAccounts: emailAccounts,
      emailNewsletter: emailNewsletter,
      creditControlName: creditControlName,
      creditControlEmail: creditControlEmail,
      practiceNr: practiceNr,
      hpcsaNr: hpcsaNr,
      mhgUsername: mhgUsername,
      mhgPassword: mhgPassword,
      vatNr: vatNr,
      acceptContact: acceptContact,
      agreementCheck: agreementCheck,
      idDocument: idDocument,
      companyDocument: companyDocument,
      bankDetails: bankDetails,
      IOCellNo: IOCellNo,
      emailPracticeManager: emailPracticeManager,
      businessType: businessType,
      heardFrom: heardFrom,
      socialMediaPlatforms: socialMediaPlatforms,
      debitorderBool:debitorderBool,
      bank:bank,
      branch:branch,
      branchCode:branchCode,
      accountName:accountName,
      accountNo:accountNo,
      accountType:accountType,
      dateOfDebitOrder:dateOfDebitOrder,
      allowedToSign:allowedToSign,
      signedOnBehalfOf:signedOnBehalfOf,
      dateSigned:dateSigned,
      signedName:signedName,
      signedSurname:signedSurname,
      signedRole:signedRole,
      signedPlace:signedPlace,
      signedCell:signedCell,
      signedEmail:signedEmail,
    });
    if (resp.ok) {
      toast.success('Your response has been succesfully sent.');
      window.location.reload();
      setIsLoading(false);
      } else {
        toast.error(resp.statusText + '. Contact GoodX for further assistance.');
        setIsLoading(false);
      }
    };

  const businessSubmit = async(e: any) => {
    e.preventDefault();

    const businessNameValidateErr = validateModelFields(e, 'none', 'business_name', businessName, setBusinessNameErr, businessDetailsValidation, true);
    const tradingAsValidateErr = validateModelFields(e, 'none', 'trading_as', tradingAs, setTradingAsErr, businessDetailsValidation, true);
    const IdRegValidateErr = validateModelFields(e, 'none', 'id_reg_nr', idRegNr, setIdRegNrErr, businessDetailsValidation);
    const vatNumberValidateErr = validateModelFields(e, 'none', 'vat_number', vatNr, setVatNrErr, businessDetailsValidation);
    const contactPersonValidateErr = validateModelFields(e, 'none', 'contact_person', contactPerson, setContactPersonErr, businessDetailsValidation);
    const disciplineValidateErr = validateModelFields(e, 'none', 'discipline', discipline, setDisciplineErr, businessDetailsValidation);
    const postalAddressValidateErr = validateModelFields(e, 'none', 'postal_address', postalAddress, setPostalAddressErr, businessDetailsValidation);
    const postalAddress2ValidateErr = validateModelFields(e, 'none', 'postal_address2', postalAddress2, setPostalAddressErr2, businessDetailsValidation);
    const postalAddress3ValidateErr = validateModelFields(e, 'none', 'postal_address3', postalAddress3, setPostalAddressErr3, businessDetailsValidation);
    const streetAddressValidateErr = validateModelFields(e, 'none', 'street_address', streetAddress, setStreetAddressErr, businessDetailsValidation);
    const streetAddress2ValidateErr = validateModelFields(e, 'none', 'street_address2', streetAddress2, setStreetAddressErr2, businessDetailsValidation);
    const streetAddress3ValidateErr = validateModelFields(e, 'none', 'street_address3', streetAddress3, setStreetAddressErr3, businessDetailsValidation);
    const telNrValidateErr = validateModelFields(e, 'phone', 'tel_nr', telNr, setTelNrErr, businessDetailsValidation);
    const cellNrValidateErr = validateModelFields(e, 'phone', 'cell_nr', cellNr, setCellNrErr, businessDetailsValidation);
    const emailDebitOrderValidateErr = validateModelFields(e, 'email', 'email_debit_order', emailDebitOrder, setEmailDebitOrderErr, businessDetailsValidation);
    const emailAccountsValidateErr = validateModelFields(e, 'email', 'email_accounts', emailAccounts, setEmailAccountsErr, businessDetailsValidation);
    const emailNewsletterValidateErr = validateModelFields(e, 'email', 'email_newsletter', emailNewsletter, setEmailsNewsletterErr, businessDetailsValidation);
    const emailPracticeManagerValidateErr = validateModelFields(e, 'email', 'email_practice_manager', emailPracticeManager, setEmailPracticeManagerErr, businessDetailsValidation);
    const creditControlNameValidateErr = validateModelFields(e, 'none', 'credit_control_name', creditControlName, setCreditControlNameErr, businessDetailsValidation);
    const creditControlEmailValidateErr = validateModelFields(e, 'email', 'credit_control_email', creditControlEmail, setCreditControlEmailErr, businessDetailsValidation);
    const IOCellNrValidateErr = validateModelFields(e, 'phone', 'io_cell_nr', IOCellNo, setIOCellNoErr, businessDetailsValidation);
    const practiceNrValidateErr = validateModelFields(e, 'none', 'practice_nr', practiceNr, setPracticeNrErr, businessDetailsValidation);
    const hpcsaNrValidateErr = validateModelFields(e, 'none', 'hpcsa_nr', hpcsaNr, setHpcsaNrErr, businessDetailsValidation);
    const acceptContactValidateErr = validateModelFields(e, 'none', 'accept_contact', acceptContact, setAcceptContactErr, businessDetailsValidation);
    const mhgUsernameValidateErr = validateModelFields(e, 'none', 'mhg_username', mhgUsername, setMHGUsernameErr, businessDetailsValidation);
    const mhgPasswordValidateErr = validateModelFields(e, 'none', 'mhg_password', mhgPassword, setMHGPasswordErr, businessDetailsValidation);
    const businessTypeValidateErr = validateModelFields(e, 'select', 'business_type', businessType, setBusinessTypeErr, businessDetailsValidation);
    const faxNrValidateErr = validateModelFields(e, 'phone', 'fax_nr', faxNr, setFaxNrErr, businessDetailsValidation);
    const streetCodeValidationErr = validateModelFields(e, 'none', 'street_code', streetCode, setStreetCodeErr, businessDetailsValidation);
    const postalCodeValidationErr = validateModelFields(e, 'none', 'postal_code', postalCode, setPostalCodeErr, businessDetailsValidation);

    let debitOrderBankValidateErr = false;
    let debitOrderBranchValidateErr = false;
    let debitOrderAccountNameValidateErr = false;
    let debitOrderAccountTypeValidateErr = false;
    let debitOrderDateValidateErr = false;
    let debitOrderAccountNoValidateErr = false;
    let debitOrderBranchCodeValidateErr = false;

    if (quoteConfig.debit_order) {
      debitOrderBankValidateErr = validateModelFields(e, 'none', 'debit_order_bank', bank, setBankError, businessDetailsValidation) || false;
      debitOrderBranchValidateErr = validateModelFields(e, 'none', 'debit_order_branch', branch, setBankBranchError, businessDetailsValidation) || false;
      debitOrderAccountNameValidateErr = validateModelFields(e, 'none', 'debit_order_account_name', accountName, setAccountNameError, businessDetailsValidation) || false;
      debitOrderAccountTypeValidateErr = validateModelFields(e, 'none', 'debit_order_account_type', accountType, setAccountTypeError, businessDetailsValidation) || false;
      debitOrderDateValidateErr = validateModelFields(e, 'none', 'date_of_debit_order', dateOfDebitOrder, setDateOfDebitOrderError, businessDetailsValidation) || false;
      debitOrderAccountNoValidateErr = validateModelFields(e, 'none', 'debit_order_account_no', accountNo, setAccountNoError, businessDetailsValidation) || false;
      debitOrderBranchCodeValidateErr = validateModelFields(e, 'none', 'debit_order_branch_code', branchCode, setBranchCodeError, businessDetailsValidation) || false;      
    }
  
    const seenSocialMediaValidateErr = validateModelFields(e, 'select', 'seen_social_media', seenSocialMedia, setSeenSocialMediaErr, businessDetailsValidation);
    const heardFromValidateErr = validateModelFields(e, 'none', 'heard_from', heardFrom, setHeardFromErr, businessDetailsValidation);
    const IdDocumentValidateErr = validateModelFields(e, 'none', 'id_document', idDocument, setIdDocumentErr, businessDetailsValidation);
    const companyDocumentValidateErr = validateModelFields(e, 'none', 'company_document', companyDocument, setCompanyDocumentErr, businessDetailsValidation);
    const bankingDetailsValidateErr = validateModelFields(e, 'none', 'banking_details', bankDetails, setBankDetailsErr, businessDetailsValidation);

    if (!businessNameValidateErr && !tradingAsValidateErr && !IdRegValidateErr && !contactPersonValidateErr && !disciplineValidateErr
      && !postalAddressValidateErr && !streetAddressValidateErr && !telNrValidateErr && !cellNrValidateErr && !emailDebitOrderValidateErr
      && !emailAccountsValidateErr && !emailNewsletterValidateErr && !emailPracticeManagerValidateErr && !creditControlNameValidateErr 
      && !creditControlEmailValidateErr && !IOCellNrValidateErr && !practiceNrValidateErr && !hpcsaNrValidateErr && !acceptContactValidateErr
      && !debitOrderBankValidateErr && !debitOrderBranchValidateErr && !debitOrderAccountNameValidateErr && !debitOrderAccountTypeValidateErr
      && !debitOrderDateValidateErr && !debitOrderAccountNoValidateErr && !debitOrderBranchCodeValidateErr && !seenSocialMediaValidateErr
      && !heardFromValidateErr && !IdDocumentValidateErr && !companyDocumentValidateErr && !bankingDetailsValidateErr && !mhgUsernameValidateErr
      && !mhgPasswordValidateErr && !businessTypeValidateErr && !vatNumberValidateErr && !postalAddress2ValidateErr && !postalAddress3ValidateErr
      && !streetAddress2ValidateErr && !streetAddress3ValidateErr && !faxNrValidateErr && !streetCodeValidationErr && !postalCodeValidationErr
      ) 
      {
      BusinessDetailsSave(
        id,
        businessName,
        tradingAs,
        idRegNr,
        contactPerson,
        discipline,
        postalAddress,
        postalAddress2,
        postalAddress3,
        postalCode,
        streetAddress,
        streetAddress2,
        streetAddress3,
        streetCode,
        telNr,
        cellNr,
        faxNr,
        emailDebitOrder,
        emailAccounts,
        emailNewsletter,
        creditControlName,
        creditControlEmail,
        practiceNr,
        hpcsaNr,
        mhgUsername,
        mhgPassword,
        vatNr,
        acceptContact,
        agreementCheck,
        idDocument,
        companyDocument,
        bankDetails,
        IOCellNo,
        emailPracticeManager,
        businessType,
        heardFrom as any,
        seenSocialMedia,
        !debitorderBool,
        bank,
        branch,
        branchCode,
        accountName,
        accountNo,
        accountType,
        dateOfDebitOrder,
        false, 
        '', 
        new Date(),
        '',
        '',
        '',
        '',
        '',
        ''
      );
    }    
  };

  const marketerSubmit = async() => {
        marketerSubmitSave(
          id,
          businessName,
          tradingAs,
          idRegNr,
          contactPerson,
          discipline,
          postalAddress,
          postalAddress2,
          postalAddress3,
          postalCode,
          streetAddress,
          streetAddress2,
          streetAddress3,
          streetCode,
          telNr,
          cellNr,
          faxNr,
          emailDebitOrder,
          emailAccounts,
          emailNewsletter,
          creditControlName,
          creditControlEmail,
          practiceNr,
          hpcsaNr,
          mhgUsername,
          mhgPassword,
          vatNr,
          acceptContact,
          agreementCheck,
          idDocument,
          companyDocument,
          bankDetails,
          IOCellNo,
          emailPracticeManager,
          businessType,
          heardFrom as any,
          seenSocialMedia,
          !debitorderBool,
          bank,
          branch,
          branchCode,
          accountName,
          accountNo,
          accountType,
          dateOfDebitOrder
        );
  };
      
  function applyStyling(fileInput: any, mode: any) {
    if (mode == 1) {
      fileInput.style.border = '2px solid #ccc';
      fileInput.style.backgroundColor = '#44AF69';
      fileInput.style.color = '#fffff';
      fileInput.style.height = '36px';
      fileInput.style.borderRadius = '66px';
      fileInput.style.fontWeight = '500';
      fileInput.style.fontSize = '16px';
      fileInput.style.textTransform = 'uppercase';
      fileInput.style.padding = '0 16px';
    }
    else {
      fileInput.style.border = '2px solid #ccc';
      fileInput.style.backgroundColor = '#E0323B';
      fileInput.style.color = '#fffff';
      fileInput.style.height = '36px';
      fileInput.style.borderRadius = '66px';
      fileInput.style.fontWeight = '500';
      fileInput.style.fontSize = '16px';
      fileInput.style.textTransform = 'uppercase';
      fileInput.style.padding = '0 16px';
    }
  };
  
  const initializeFileInput = (
    uploadedDocs: string | null,
    defaultFileLoaded: boolean,
    fileInputId: string,
    applyStyling: (fileInput: HTMLInputElement, styleCode: number) => void,
    setDefaultFileLoaded: (fileInputId: string, loaded: boolean) => void
  ) => {
    const fileInput = document.querySelector<HTMLInputElement>(`input[type="file"][id="${fileInputId}"]`);
    if (uploadedDocs && !defaultFileLoaded) {
      if (fileInput !== null) {
        const myFile = new File(['Uploaded'], 'Uploaded', { type: 'text/plain' });
  
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(myFile);
        fileInput.files = dataTransfer.files;
  
        applyStyling(fileInput, 1);
        setDefaultFileLoaded(fileInputId, true);
      } else {
        console.error("File input element not found.");
      }
    }
  };

  const handleFileInputChange = (
    fileInputId: string,
    applyStyling: (fileInput: HTMLInputElement, styleCode: number) => void,
    setIdDocumentErr: (message: string) => void,
    setCompanyDocumentErr: (message: string) => void,
    setBankError: (message: string) => void
  ) => {
    const fileInput = document.querySelector<HTMLInputElement>(`input[type="file"][id="${fileInputId}"]`);
    if (fileInput) {
      fileInput.onchange = () => {
        if (fileInput.files) {
          if (fileInput.files.length === 0) {
            applyStyling(fileInput, 2);
            if (fileInputId === 'id_document') {
              setIdDocumentErr('Please attach ID document');
            }
            if (fileInputId === 'company_document') {
              setCompanyDocumentErr('Please attach Company document');
            }
            if (fileInputId === 'banking_details') {
              setBankError('Please attach proof of banking detail');
            }
          } else {
            applyStyling(fileInput, 1);
          }
        } else {
          console.error("File input files not available.");
        }
      };
    }
  };

  const setDefaultFileLoaded = (fileInputId: string, loaded: boolean) => {
    setDefaultFileLoadedState(prevState => ({ ...prevState, [fileInputId]: loaded }));
  };

  useEffect(() => {
    initializeFileInput(idDocument, defaultFileLoaded.iddoc, 'id_document', applyStyling, setDefaultFileLoaded);
    initializeFileInput(companyDocument, defaultFileLoaded.companydoc, 'company_document', applyStyling, setDefaultFileLoaded);
    initializeFileInput(bankDetails, defaultFileLoaded.bankdoc, 'banking_details', applyStyling, setDefaultFileLoaded);
  }, [idDocument, companyDocument, bankDetails]);

  useEffect(() => {
    handleFileInputChange('id_document', applyStyling, setIdDocumentErr, setCompanyDocumentErr, setBankError);
    handleFileInputChange('company_document', applyStyling, setIdDocumentErr, setCompanyDocumentErr, setBankError);
    handleFileInputChange('banking_details', applyStyling, setIdDocumentErr, setCompanyDocumentErr, setBankError);
  }, []);

  useEffect(() => {
   fetchSpecialities('/quotation/speciality', setDisciplineApi);
   fetchBusinessDetails(`/quotation/business_details/${id}`, setBusinessValues, setBusinessDetailsLoading);
   fetchBusinessDetails(`/quotation/${id}`, setQuote, setBusinessDetailsLoading);
   fetchSpecialities(`/quotation/config`, setQuoteConfig);
  }, []);
  
  useEffect(() => {
    setTradingAs(businessValues.trading_as || '');
    setBusinessName(businessValues.business_name || '');
    setBusinessType(businessValues.business_type || '');
    setIdRegNr(businessValues.id_reg_nr || '');
    setContactPerson(businessValues.contact_person || '');
    setDiscipline(businessValues.discipline_id || '');
    setPostalAddress(businessValues.postal_address || '');
    setPostalAddress2(businessValues.postal_address2 || '');
    setPostalAddress3(businessValues.postal_address3 || '');
    setStreetAddress(businessValues.street_address || '');
    setStreetAddress2(businessValues.street_address2 || '');
    setStreetAddress3(businessValues.street_address3 || '');
    setStreetCode(businessValues.street_code || '');
    setPostalCode(businessValues.postal_code || '');
    setTelNr(businessValues.tel_nr || 0);
    setCellNr(businessValues.cell_nr || 0);
    setFaxNr(businessValues.fax_nr || '');
    setEmailAccounts(businessValues.email_accounts || '');
    setEmailDebitOrder(businessValues.email_debit_order || '');
    setEmailsNewsletter(businessValues.email_newsletter || '');
    setEmailPracticeManager(businessValues.email_practice_manager || '');
    setCreditControlName(businessValues.credit_control_name || '');
    setCreditControlEmail(businessValues.credit_control_email || '');
    setIOCellNo(businessValues.io_cell_nr || 0); 
    setPracticeNr(businessValues.practice_nr || '');
    setHpcsaNr(businessValues.hpcsa_nr || '');
    setMHGUsername(businessValues.mhg_username || '');
    setMHGPassword(businessValues.mhg_password || '');
    setVatNr(businessValues.vat_number || '');
    setAcceptContact(businessValues.accept_contact || '');
    setDebitOrderBool(businessValues.debit_order_bool || false);
    setBank(businessValues.debit_order_bank || '');
    setBranch(businessValues.debit_order_branch || '');
    setBranchCode(businessValues.debit_order_branch_code || '');
    setAccountName(businessValues.debit_order_account_name || '');
    setAccountNo(businessValues.debit_order_account_no || '');
    setAccountType(businessValues.debit_order_account_type || '');
    setDateOfDebitOrder(businessValues.date_of_debit_order || '');  
    setAgreementCheck(businessValues.accept_terms || false); 
    setSeenSocialMedia(businessValues.seen_social_media || '');
    setHeardFrom(businessValues.heard_from || '');
    setIdDocument(businessValues.id_document || '');
    setCompanyDocument(businessValues.company_document || '');
    setBankDetails(businessValues.banking_details || '');
    setDebitOrderBool(quoteConfig.debit_order || false);
  }, [businessValues, quoteConfig]);

  useEffect(() => {
    if (postalAddress === '*') {
      setPostalAddress(streetAddress);
      setPostalAddress2(streetAddress2);
      setPostalAddress3(streetAddress3);
      setPostalCode(streetCode);
    }
  }, [postalAddress, streetAddress, streetAddress2, streetAddress3]);

  return (
    <>
    {businessDetailsLoading ?
    <>
    <DesktopLayout>
    <div className={styles.FormWrapper}>
      <div className={styles.notification}>
        <p className='BannerText'><strong>Info!</strong> To start the installation process, please complete the form below.</p>
      </div>
      
      <form>
        <div className={styles.FormHeader}>
          <div className={styles.Heading}>
            <h2>Quote: Part B</h2>
          </div>
        </div>
        <br />
        <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
        <div className={styles.FieldGroupSingle}>
          <TextInputValidation
            editing={true}
            name={getFieldLabel('business_name', businessDetailsValidation, false)}
            onBlur={
              (e:any) => {
                validateModelFields(e, 'none', 'business_name', businessName, setBusinessNameErr, businessDetailsValidation)
                BusinessDetailsUpdate('business_name', businessName)
              }
            }
            onChange={(event) => setBusinessName(event)} 
            errors={businessNameErr}
            value={businessName}
            id={'business_name'}
          />

          <TextInputValidation
            editing={true}
            name={getFieldLabel('trading_as', businessDetailsValidation, false)}
            onBlur={
              (e:any) => {
                validateModelFields(e, 'none', 'trading_as', tradingAs, setTradingAsErr, businessDetailsValidation)
                BusinessDetailsUpdate('trading_as', tradingAs)
              }
            }
            onChange={(event) => setTradingAs(event)}
            errors={tradingAsErr}
            value={tradingAs}
            id={'trading_as'}
          />

          <label className={styles.left}>{getFieldLabel('business_type', businessDetailsValidation, false)}</label>
          <RadioGroup className={styles.row} row aria-label="position" value={businessType} 
            onChange={(e) => setBusinessType(e.target.value)}
            onBlur={(e:any) => {
              validateModelFields(e, 'none', 'business_type', businessType, setBusinessTypeErr, businessDetailsValidation)
              BusinessDetailsUpdate('business_type', businessType)
              }
            }
            id={'business_type'}
          >
            <FormControlLabel value="Sole Practitioner" control={<Radio />} label="Sole Practitioner" />
            <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
            <FormControlLabel value="Incorporation" control={<Radio />} label="Incorporation" />
            <FormControlLabel value="Close Corporation" control={<Radio />} label="Close Corporation" />
            <FormControlLabel value="(Pty) Ltd" control={<Radio />} label="(Pty) Ltd" />
            <FormControlLabel value="Trust" control={<Radio />} label="Trust" />
          </RadioGroup>
          <span className={styles.ErrorMsg}>{businessTypeErr}</span>
        </div>
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('vat_number', businessDetailsValidation, false)}
              onBlur={(e: any) => {
                validateModelFields(e, 'none', 'vat_number', vatNr, setVatNrErr, businessDetailsValidation)
                BusinessDetailsUpdate('vat_number', vatNr)
                }
              }
              onChange={(event) => setVatNr(event)}
              value={vatNr}
              id={'vat_number'}
              errors={vatNrErr}
            />
            </div>
          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('id_reg_nr', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'id_reg_nr', idRegNr, setIdRegNrErr, businessDetailsValidation)
                  BusinessDetailsUpdate('id_reg_nr', idRegNr)
                }
              }
              onChange={(event) => setIdRegNr(event)}
              errors={idRegNrErr}
              value={idRegNr}
              id={'id_reg_nr'}
            />
          </div>
        </div>

        <label className={styles.row} style={{ color: 'red' }}>Use an asterisk '*' in the postal address to copy the street address</label>
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
          <h2 className={bstyles.FieldHeader}>Street Address</h2>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('street_address', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'street_address', streetAddress, setStreetAddressErr, businessDetailsValidation)
                  BusinessDetailsUpdate('street_address', streetAddress)
                }
              }
              onChange={(event) => setStreetAddress(event)}
              errors={streetAddressErr}
              value={streetAddress}
              id={'street_address'}
            />

            <TextInputValidation
              editing={true}
              name={getFieldLabel('street_address2', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'street_address2', streetAddress2, setStreetAddressErr2, businessDetailsValidation)
                  BusinessDetailsUpdate('street_address2', streetAddress2)
                }
              }
              onChange={(event) => setStreetAddress2(event)}
              errors={streetAddressErr2}
              value={streetAddress2}
              id={'street_address2'}
            />
            <TextInputValidation
              editing={true}
              name={getFieldLabel('street_address3', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'street_address3', streetAddress3, setStreetAddressErr3, businessDetailsValidation)
                  BusinessDetailsUpdate('street_address3', streetAddress3)
                }
              }
              onChange={(event) => setStreetAddress3(event)}
              errors={streetAddressErr3}
              value={streetAddress3}
              id={'street_address3'}
            />
            <TextInputValidation
              editing={true}
              name={getFieldLabel('street_code', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'street_code', streetCode, setStreetCodeErr, businessDetailsValidation)
                  BusinessDetailsUpdate('street_code', streetCode)
                }
              }
              onChange={(event) => setStreetCode(event)}
              errors={streetCodeErr}
              value={streetCode}
              id={'street_code'}
            />
          </div>
          <div className={styles.FieldGroupR}>
            <h2 className={bstyles.FieldHeader}>Postal Address</h2>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('postal_address', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'postal_address', postalAddress, setPostalAddressErr, businessDetailsValidation)
                  if (e.target.value.includes('*')) { 
                    BusinessDetailsUpdate('postal_address', streetAddress);
                    BusinessDetailsUpdate('postal_address2', streetAddress2);
                    BusinessDetailsUpdate('postal_address3', streetAddress3);
                  }
                  else {
                    BusinessDetailsUpdate('postal_address', postalAddress);
                  }
                }
              }
              onChange={(event) => setPostalAddress(event)}
              errors={postalAddressErr}
              value={postalAddress}
              id={'postal_address'}
            />

            <TextInputValidation
              editing={true}
              name={getFieldLabel('postal_address2', businessDetailsValidation, false)}
              onBlur={(e: any) => {
                validateModelFields(e, 'none', 'postal_address2', postalAddress2, setPostalAddressErr2, businessDetailsValidation)
                BusinessDetailsUpdate('postal_address2', postalAddress2)
              }}
              onChange={(event) => setPostalAddress2(event)}
              errors={postalAddressErr2}
              value={postalAddress2}
              id={'postal_address2'}
            />
            <TextInputValidation
              editing={true}
              name={getFieldLabel('postal_address3', businessDetailsValidation, false)}
              onBlur={(e: any) => {
                validateModelFields(e, 'none', 'postal_address3', postalAddress3, setPostalAddressErr3, businessDetailsValidation)
                BusinessDetailsUpdate('postal_address3', postalAddress3)
              }}
              onChange={(event) => setPostalAddress3(event)}
              errors={postalAddressErr3}
              value={postalAddress3}
              id={'postal_address3'}
            />
            <TextInputValidation
              editing={true}
              name={getFieldLabel('postal_code', businessDetailsValidation, false)}
              onBlur={(e: any) => {
                validateModelFields(e, 'none', 'postal_code', postalCode, setPostalCodeErr, businessDetailsValidation)
                BusinessDetailsUpdate('postal_code', postalCode)
              }}
              onChange={(event) => setPostalCode(event)}
              errors={postalCodeErr}
              value={postalCode}
              id={'postal_code'}
            />
          </div>   
        </div>

        <div className={styles.FieldGroupSingle}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Contact Details</h2>
          <TextInputValidation
            editing={true}
            name={getFieldLabel('contact_person', businessDetailsValidation, false)}
            onBlur={
              (e:any) => {
                validateModelFields(e, 'none', 'contact_person', contactPerson, setContactPersonErr, businessDetailsValidation)
                BusinessDetailsUpdate('contact_person', contactPerson)
              }
            }
            onChange={(event) => setContactPerson(event)}
            errors={contactPersonErr}
            value={contactPerson}
            id={'contact_person'}
          />
        </div>
        <div className={styles.FieldGroupTripple}>
          <div className={styles.FieldGroupLL}>
            <TextInputValidation
              name={getFieldLabel('tel_nr', businessDetailsValidation, false)}
              editing={true}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'phone', 'tel_nr', telNr, setTelNrErr, businessDetailsValidation)
                  BusinessDetailsUpdate('tel_nr', telNr)
                }
              }
              onChange={(event:any) => setTelNr(event)}
              errors={telNrErr}
              value={String(telNr)}
              maxLength={10}
              id={'tel_nr'}
            />
          </div>

          <div className={styles.FieldGroupCC}>
            <TextInputValidation
              name={getFieldLabel('cell_nr', businessDetailsValidation, false)}
              editing={true}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'phone', 'cell_nr', cellNr, setCellNrErr, businessDetailsValidation)
                  BusinessDetailsUpdate('cell_nr', cellNr)
                }
              }
              value={String(cellNr)}
              onChange={(event:any) => setCellNr(event)}
              errors={cellNrErr}
              maxLength={10}
              id={'cell_nr'}
            />
          </div>

          <div className={styles.FieldGroupRR}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('fax_nr', businessDetailsValidation, false)}
              onBlur={(e:any) => {
                validateModelFields(e, 'phone', 'fax_nr', faxNr, setFaxNrErr, businessDetailsValidation)
                BusinessDetailsUpdate('fax_nr', faxNr)
                }
              }
              onChange={(event) => setFaxNr(event)}
              errors={faxNrErr}
              value={faxNr}
              maxLength={10}
              id={'fax_nr'}
            />
          </div>
        </div>

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('email_debit_order', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'email', 'email_debit_order', emailDebitOrder, setEmailDebitOrderErr, businessDetailsValidation)
                  BusinessDetailsUpdate('email_debit_order', emailDebitOrder)
                }  
              }
              onChange={(event) => setEmailDebitOrder(event)}
              errors={emailDebitOrderErr}
              value={emailDebitOrder}
              id={'email_debit_order'}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('email_newsletter', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'email', 'email_newsletter', emailNewsletter, setEmailsNewsletterErr, businessDetailsValidation)
                  BusinessDetailsUpdate('email_newsletter', emailNewsletter)
                }  
              }
              onChange={(event) => setEmailsNewsletter(event)}
              errors={emailNewsletterErr}
              value={emailNewsletter}
              id={'email_newsletter'}
            />
          </div>
        </div>

        <div className={styles.FieldGroupDouble}>  
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('email_practice_manager', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'email', 'email_practice_manager', emailPracticeManager, setEmailPracticeManagerErr, businessDetailsValidation)
                  BusinessDetailsUpdate('email_practice_manager', emailPracticeManager)
                }
              }
              onChange={(event) => setEmailPracticeManager(event)}
              errors={emailPracticeManagerErr}
              value={emailPracticeManager}
              id={'email_practice_manager'}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('email_accounts', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'email', 'email_accounts', emailAccounts, setEmailAccountsErr, businessDetailsValidation)
                  BusinessDetailsUpdate('email_accounts', emailAccounts)
                }
              }
              onChange={(event) => setEmailAccounts(event)}
              errors={emailAccountsErr}
              value={emailAccounts}
              id={'email_accounts'}
            />
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Information Officer Details</h2>
          <TextInputValidation
            editing={true}
            name={getFieldLabel('credit_control_name', businessDetailsValidation, false)}
            onBlur={
              (e:any) => {
                validateModelFields(e, 'none', 'credit_control_name', creditControlName, setCreditControlNameErr, businessDetailsValidation)
                BusinessDetailsUpdate('credit_control_name', creditControlName)
              }
            }
            onChange={(event) => setCreditControlName(event)}
            errors={creditControlNameErr}
            value={creditControlName}
            id={'credit_control_name'}
          />
        </div>

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>  
            <TextInputValidation
              name={getFieldLabel('io_cell_nr', businessDetailsValidation, false)}
              editing={true}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'number', 'io_cell_nr', IOCellNo, setIOCellNoErr, businessDetailsValidation)
                  BusinessDetailsUpdate('io_cell_nr', IOCellNo)
                }
              }
              onChange={(event:any) => setIOCellNo(event)}
              errors={IOCellNoErr}
              value={String(IOCellNo)}
              maxLength={10}
              id={'io_cell_nr'}
            />  
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('credit_control_email', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'email', 'credit_control_email', creditControlEmail, setCreditControlEmailErr, businessDetailsValidation)
                  BusinessDetailsUpdate('credit_control_email', creditControlEmail)
                }
              }
              onChange={(event) => setCreditControlEmail(event)}
              errors={creditControlEmailErr}
              value={creditControlEmail}
              id={'credit_control_email'}
            />
          </div>
        </div>

        <div className={styles.FieldGroupSingle}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Practice Details</h2>
          <label className={styles.left}>{getFieldLabel('discipline', businessDetailsValidation, false)}</label>
          <select
            className={disciplineErr ? 'Error' : ''}
            value={discipline}
            onBlur={(e:any) => {
              validateModelFields(e, 'none', 'discipline', discipline, setDisciplineErr, businessDetailsValidation)
              BusinessDetailsUpdate('discipline', discipline)
            }
            }
            onChange={(e) => {
              setDiscipline(e.target.value)
              BusinessDetailsUpdate('discipline', discipline)
            }}
            id={'discipline'}
            >
            <option></option>
            {disciplineApi.map((item: any) => (
              <option key={item.name} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('practice_nr', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'practice_nr', practiceNr, setPracticeNrErr, businessDetailsValidation)
                  BusinessDetailsUpdate('practice_nr', practiceNr)
                }
              }
              onChange={(event) => setPracticeNr(event)}
              errors={practiceNrErr}
              value={practiceNr}
              maxLength={7}
              id={'practice_nr'}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('hpcsa_nr', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'hpcsa_nr', hpcsaNr, setHpcsaNrErr, businessDetailsValidation)
                  BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                }
              }
              onChange={(event) => setHpcsaNr(event)}
              errors={hpcsaNrErr}
              value={hpcsaNr}
              id={'hpcsa_nr'}
            />
          </div>
        </div>
        
        <div className={styles.FieldGroupDouble}>
          <div className={styles.FieldGroupL}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('mhg_username', businessDetailsValidation, false)}
              onBlur={(e:any) => {
                validateModelFields(e, 'none', 'mhg_username', mhgUsername, setMHGUsernameErr, businessDetailsValidation)
                BusinessDetailsUpdate('mhg_username', mhgUsername)
                }
              }
              onChange={(event) => setMHGUsername(event)}
              errors={mhgUsernameErr}
              value={mhgUsername}
              id={'mhg_username'}
            />
          </div>

          <div className={styles.FieldGroupR}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('mhg_password', businessDetailsValidation, false)}
              onBlur={(e:any) => {
                validateModelFields(e, 'none', 'mhg_password', mhgPassword, setMHGPasswordErr, businessDetailsValidation)
                BusinessDetailsUpdate('mhg_password', mhgPassword)
                }
              }
              onChange={(event) => setMHGPassword(event)}
              errors={mhgPasswordErr}
              value={mhgPassword}
              id={'mhg_password'}
            />
          </div>
        </div>
        <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Upload Documents</h2>
        <div className={styles.FieldGroupTripple}>
          <div className={styles.FieldGroupLL}>
            <InputUpload
              name={getFieldLabel('id_document', businessDetailsValidation, false)}
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('id_document', idDocument)}
              errors={fileLT2MBValidator(idDocument) || idDocumentErr ? [fileLT2MBValidator(idDocument), idDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setIdDocument(event)
                BusinessDetailsUpdate('id_document', event)
              }}
              id={'id_document'}
            />
          </div>
          <div className={styles.FieldGroupCC}>
            <InputUpload
              name={getFieldLabel('company_document', businessDetailsValidation, false)}
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('company_document', companyDocument)}
              errors={fileLT2MBValidator(companyDocument) || companyDocumentErr ? [fileLT2MBValidator(companyDocument), companyDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setCompanyDocument(event)
                BusinessDetailsUpdate('company_document', event)
              }}
              id={'company_document'}
            />
          </div>
          
            <div className={styles.FieldGroupRR}>
              <InputUpload
                name={getFieldLabel('banking_details', businessDetailsValidation, false)}
                onBlur={() => BusinessDetailsUpdate('banking_details', bankDetails)}
                errors={fileLT2MBValidator(bankDetails) || bankDetailsErr ? [fileLT2MBValidator(bankDetails), bankDetailsErr].filter(Boolean) : null}
                onChange={(event: any) => {
                  setBankDetails(event)
                  BusinessDetailsUpdate('banking_details', event)
                }}
                id={'banking_details'}
              />
            </div>
          </div>
          {quoteConfig?.debit_order === true ?
          <>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Banking Details</h2>
          <div className={styles.FieldGroupDouble}>
            <div className={styles.FieldGroupL}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('debit_order_account_name', businessDetailsValidation, false)}
                onChange={(event) => setAccountName(event)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'none', 'debit_order_account_name', accountName, setAccountNameError, businessDetailsValidation)
                    BusinessDetailsUpdate('debit_order_account_name', accountName)
                  }
                }
                errors={accountNameError}
                value={accountName}
                id={'debit_order_account_name'}
                />
              </div>
                <div className={styles.FieldGroupR}>
                  <TextInputValidation
                    editing={true}
                    name={getFieldLabel('debit_order_bank', businessDetailsValidation, false)}
                    onChange={(event) => setBank(event)}
                    onBlur={
                      (e:any) => { 
                        validateModelFields(e, 'none', 'debit_order_bank', bank, setBankError, businessDetailsValidation)
                        BusinessDetailsUpdate('debit_order_bank', bank)
                      }
                    }
                    errors={bankError}
                    value={bank}
                    id={'debit_order_bank'}
                  />
                </div>
              </div>

              <div className={styles.FieldGroupDouble}>
                <div className={styles.FieldGroupL}>
                  <TextInputValidation
                    editing={true}
                    name={getFieldLabel('debit_order_account_no', businessDetailsValidation, false)}
                    onChange={(event) => setAccountNo(event)}
                    onBlur={
                      (e:any) => {
                        validateModelFields(e, 'none', 'debit_order_account_no', accountNo, setAccountNoError, businessDetailsValidation)
                        BusinessDetailsUpdate('debit_order_account_no', accountNo)
                      }
                    }
                    errors={accountNoError}
                    value={accountNo || 0}
                    id={'debit_order_account_no'}
                  />
                </div>
              
                <div className={styles.FieldGroupR}>
                  <TextInputValidation
                    editing={true}
                    name={getFieldLabel('debit_order_branch', businessDetailsValidation, false)}
                    onChange={(event) => setBranch(event)}
                    onBlur={
                      (e:any) => {
                        validateModelFields(e, 'none', 'debit_order_branch', branch, setBankBranchError, businessDetailsValidation)
                        BusinessDetailsUpdate('debit_order_branch', branch)
                      }
                    }
                    errors={bankBranchError}
                    value={branch}
                    id={'debit_order_branch'}
                  />
                </div>
              </div>
              <div className={styles.FieldGroupDouble}>
                <div className={styles.FieldGroupL}>
                  <TextInputValidation
                    editing={true}
                    name={getFieldLabel('debit_order_branch_code', businessDetailsValidation, false)}
                    onChange={(event) => setBranchCode(event)}
                    onBlur={
                      (e:any) => {
                        validateModelFields(e, 'none', 'debit_order_branch_code', branchCode, setBranchCodeError, businessDetailsValidation)
                        BusinessDetailsUpdate('debit_order_branch_code', branchCode)
                      }
                    }
                    errors={branchCodeError}
                    value={branchCode}
                    id={'debit_order_branch_code'}
                  />
                </div>
            
                <div className={styles.FieldGroupR}>
                  <label className={styles.lineHeight29}>Account Type:</label>
                    <select
                      id={'debit_order_account_type'}
                      className={accountTypeError ? 'Error'&&bstyles.width108 : bstyles.width108}
                      value={accountType}
                      name={getFieldLabel('debit_order_account_type', businessDetailsValidation, false)}
                      onBlur={
                        (e:any) => {
                          validateModelFields(e, 'none', 'debit_order_account_type', accountType, setAccountTypeError, businessDetailsValidation)
                          BusinessDetailsUpdate('debit_order_account_type', accountType)
                        }
                      }
                      onChange={(event) => setAccountType(event.target.value)}>
                          <option> </option>
                          <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                          <option key={2} value="Savings">Savings</option>
                          <option key={3} value="transmission">transmission</option>
                  </select>
                </div>
              </div>
              <div className={styles.FieldGroupSingle}>
                <label>{getFieldLabel('date_of_debit_order', businessDetailsValidation, false)}</label>
                  <select
                    id={'date_of_debit_order'}
                    className={dateOfDebitOrderError ? 'Error' : ''}
                    value={dateOfDebitOrder}
                    name={getFieldLabel('date_of_debit_order', businessDetailsValidation, false)}
                    onBlur={
                      (e:any) => {
                        validateModelFields(e, 'none', 'date_of_debit_order', dateOfDebitOrder, setDateOfDebitOrderError, businessDetailsValidation)
                        BusinessDetailsUpdate('date_of_debit_order', dateOfDebitOrder)
                      }
                    }
                    onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                        <option> </option>
                        <option key={1} value="3">3</option>
                        <option key={2} value="7">7</option>
                        <option key={3} value="15">15</option>
                </select>
              </div>
              </>
              :<></>
           }
        <>
        { isTokenValid.isTemp ?
        <></>:
        <>
          <label>Heard From: *</label>
          <div className={styles.select}>
          <Select
            id={'heard_from'}
            className={styles.multiValue}
            isMulti={true}
            onBlur={(e) => validateModelFields(e, 'none', 'heard_from', heardFrom, setHeardFromErr, businessDetailsValidation)}
            onChange={(selectedOptions) => {
              if (selectedOptions) {
                setHeardFrom(selectedOptions.map(option => ({
                  label: option.label,
                  value: option.value
                })))
                BusinessDetailsUpdate('heardFrom', selectedOptions)
              } 
              else {
                setHeardFrom([]);
                BusinessDetailsUpdate('heardFrom', []);
              }
            }}
            options={[...HeardFromOptions]}
            value={heardFrom || []}
          />
          </div>
          <Validator errors={heardFromErr} />

          <label>{getFieldLabel('seen_social_media', businessDetailsValidation, false)}</label>
          <div className={styles.FieldGroupSingle}>
            <select
              id={'seen_social_media'}
              className={seenSocialMediaErr ? 'Error' : ''}
              value={seenSocialMedia}
              onChange={(e) => setSeenSocialMedia(e.target.value)} 
              onBlur={(e:any) => {
                validateModelFields(e, 'select', 'seen_social_media', seenSocialMedia, setSeenSocialMediaErr, businessDetailsValidation)
                BusinessDetailsUpdate('seen_social_media', seenSocialMedia)}
              }
              >
              <option></option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
            </select>
            <span className={styles.ErrorMsg}>{seenSocialMediaErr}</span>
          </div>
          <br />
          <div className={styles.TermsWrapper}>
            <p>
              I, <input className={styles.ContractUndersigned}
                id={'accept_contact'}
                name="agreement_contact"
                placeholder={"Enter Full Name"}
                onBlur={(e:any) => {
                  validateModelFields(e, 'none', 'accept_contact', acceptContact, setAcceptContactErr, businessDetailsValidation)
                  BusinessDetailsUpdate('accept_contact', acceptContact)
                }}
                onChange={(event) => setAcceptContact(event.target.value)}
                value={acceptContact}
              />, confirm that the information supplied, is correct.</p>
              <span className={styles.ErrorMsg}> {acceptContactErr}</span>
          </div>
          <br />
          <br />
          <br />
        </>
        }
        </>

      { isTokenValid.isTemp ?
        <>
        {isLoading ?
          <button
            className={styles.SubmitBtn}
            onClick={marketerSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : 
        <div className={styles.FieldGroupSingle}><button
        className={bstyles.SubmitBtns}
        onClick={marketerSubmit}
          >
        Save <span className="material-icons">arrow_forward</span>
      </button></div>
        }</>
        :<>
        {isLoading ?
          <div className={styles.fixedFooter}>
            <button
              className={styles.SubmitBtn}
              onClick={businessSubmit}
              disabled={true}
            >
              Please wait ... quote is being processed 
            </button>
          </div>
        : 
        <div className={styles.fixedFooter}>
          <button
            className={styles.SubmitBtn}
            onClick={businessSubmit}
          >
            Submit <span className="material-icons">arrow_forward</span>
          </button>
        </div>
        }</>
      }

        <ToastContainer />
        
        </form>
      </div>
      </DesktopLayout>
      
      <MobileLayout>
      <div className={responsive.PageWrapper}>
        <form>
          <div className={responsive.OverviewFirstRow}>
            <div className={styles.Heading}>
              <h2>Quote: Part B</h2>
            </div>
          </div>

          <div className={responsive.OverviewPanel}>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Business Details</h2>
            <div >
              <TextInputValidation
                editing={true}
                name={getFieldLabel('business_name', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'none', 'business_name', businessName, setBusinessNameErr, businessDetailsValidation)
                    BusinessDetailsUpdate('business_name', businessName)
                  }
                }
                onChange={(event) => setBusinessName(event)}
                errors={businessNameErr}
                value={businessName}
                id={'business_name'}
              />
              <TextInputValidation
                editing={true}
                name={getFieldLabel('trading_as', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'none', 'trading_as', tradingAs, setTradingAsErr, businessDetailsValidation)
                    BusinessDetailsUpdate('trading_as', tradingAs)
                  }
                }
                onChange={(event) => setTradingAs(event)}
                errors={tradingAsErr}
                value={tradingAs}
                id={'trading_as'}
              />
            </div>
            <br />
            <label className={styles.top}>{getFieldLabel('business_type', businessDetailsValidation, false)}</label>
            <RadioGroup className={styles.column} row aria-label="position" value={businessType} 
              id={'business_type'}
              onChange={(e) => setBusinessType(e.target.value)}
              onBlur={(e:any) => {
              validateModelFields(e, 'none', 'business_type', businessType, setBusinessTypeErr, businessDetailsValidation)
              BusinessDetailsUpdate('business_type', businessType)
              }
            }
            >
              <FormControlLabel value="Sole Practitioner" control={<Radio />} label="Sole Practitioner" />
              <FormControlLabel value="Partnership" control={<Radio />} label="Partnership" />
              <FormControlLabel value="Incorporation" control={<Radio />} label="Incorporation" />
              <FormControlLabel value="Close Corporation" control={<Radio />} label="Close Corporation" />
              <FormControlLabel value="(Pty) Ltd" control={<Radio />} label="(Pty) Ltd" />
              <FormControlLabel value="Trust" control={<Radio />} label="Trust" />
            </RadioGroup>
            <span className={styles.ErrorMsg}>{businessTypeErr}</span>
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('vat_number', businessDetailsValidation, false)}
                  onBlur={(e: any) => {
                    validateModelFields(e, 'none', 'vat_number', vatNr, setVatNrErr, businessDetailsValidation)
                    BusinessDetailsUpdate('vat_number', vatNr)
                    }
                  }
                  onChange={(event) => setVatNr(event)}
                  value={vatNr}
                  id={'vat_number'}
                />
              </div>

              <div className={responsive.FieldLayoutEndGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('id_reg_nr', businessDetailsValidation, false)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'id_reg_nr', idRegNr, setIdRegNrErr, businessDetailsValidation)
                      BusinessDetailsUpdate('id_reg_nr', idRegNr)
                    }
                  }
                  onChange={(event) => setIdRegNr(event)}
                  errors={idRegNrErr}
                  value={idRegNr}
                  id={'id_reg_nr'}
                />
              </div>
            </div>
          </div>

          <div className={responsive.OverviewPanel}>
            <h2 className={responsive.FieldHeader}>Street Address</h2>
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('street_address', businessDetailsValidation, false)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'street_address', streetAddress, setStreetAddressErr, businessDetailsValidation)
                      BusinessDetailsUpdate('street_address', streetAddress)
                    }
                  }
                  onChange={(event) => setStreetAddress(event)}
                  errors={streetAddressErr}
                  value={streetAddress}
                  id={'street_address'}
                />
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('street_address2', businessDetailsValidation, false)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'street_address2', streetAddress2, setStreetAddressErr2, businessDetailsValidation)
                      BusinessDetailsUpdate('street_address2', streetAddress2)
                    }
                  }
                  onChange={(event) => setStreetAddress2(event)}
                  errors={streetAddressErr2}
                  value={streetAddress2}
                  id={'street_address2'}
                />
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('street_address3', businessDetailsValidation, false)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'street_address3', streetAddress3, setStreetAddressErr3, businessDetailsValidation)
                      BusinessDetailsUpdate('street_address3', streetAddress3)
                    }
                  }
                  onChange={(event) => setStreetAddress3(event)}
                  errors={streetAddressErr3}
                  value={streetAddress3}
                  id={'street_address3'}
                />
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('street_code', businessDetailsValidation, false)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'street_code', streetCode, setStreetCodeErr, businessDetailsValidation)
                      BusinessDetailsUpdate('street_code', streetCode)
                    }
                  }
                  onChange={(event) => setStreetCode(event)}
                  errors={streetCodeErr}
                  value={streetCode}
                  id={'street_code'}
                />
              </div>

              <div className={responsive.FieldLayoutEndGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('postal_address', businessDetailsValidation, false)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'postal_address', postalAddress, setPostalAddressErr, businessDetailsValidation)
                      if (e.target.value.includes('*')) { 
                        BusinessDetailsUpdate('postal_address', streetAddress);
                        BusinessDetailsUpdate('postal_address2', streetAddress2);
                        BusinessDetailsUpdate('postal_address3', streetAddress3);
                      }
                      else {
                        BusinessDetailsUpdate('postal_address', postalAddress);
                      }
                    }
                  }
                  onChange={(event) => setPostalAddress(event)}
                  errors={postalAddressErr}
                  value={postalAddress}
                  id={'postal_address'}
                />
            
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('postal_address2', businessDetailsValidation, false)}
                  onBlur={(e: any) => {
                    validateModelFields(e, 'none', 'postal_address2', postalAddress2, setPostalAddressErr2, businessDetailsValidation)
                    BusinessDetailsUpdate('postal_address2', postalAddress2)
                  }}
                  onChange={(event) => setPostalAddress2(event)}
                  errors={postalAddressErr2}
                  value={postalAddress2}
                  id={'postal_address2'}
                />
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('postal_address3', businessDetailsValidation, false)}
                  onBlur={(e: any) => {
                    validateModelFields(e, 'none', 'postal_address3', postalAddress3, setPostalAddressErr3, businessDetailsValidation)
                    BusinessDetailsUpdate('postal_address3', postalAddress3)
                  }}
                  onChange={(event) => setPostalAddress3(event)}
                  errors={postalAddressErr3}
                  value={postalAddress3}
                  id={'postal_address3'}
                />
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('postal_code', businessDetailsValidation, false)}
                  onBlur={(e: any) => {
                    validateModelFields(e, 'none', 'postal_code', postalCode, setPostalCodeErr, businessDetailsValidation)
                    BusinessDetailsUpdate('postal_code', postalCode)
                  }}
                  onChange={(event) => setPostalCode(event)}
                  errors={postalCodeErr}
                  value={postalCode}
                  id={'postal_code'}
                />
              </div>
            </div>
          </div>
          <div className={responsive.OverviewPanel}>
            <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Contact Details</h2>
            <div>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('contact_person', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'contact_person', contactPerson, setContactPersonErr, businessDetailsValidation)
                  BusinessDetailsUpdate('contact_person', contactPerson)
                }
              }
              onChange={(event) => setContactPerson(event)}
              errors={contactPersonErr}
              value={contactPerson}
              id={'contact_person'}
            />
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                name={getFieldLabel('tel_nr', businessDetailsValidation, false)}
                editing={true}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'phone', 'tel_nr', telNr, setTelNrErr, businessDetailsValidation)
                    BusinessDetailsUpdate('tel_nr', telNr)
                  }
                }
                onChange={(event:any) => setTelNr(event)}
                errors={telNrErr}
                value={String(telNr)}
                maxLength={10}
                id={'tel_nr'}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                name={getFieldLabel('cell_nr', businessDetailsValidation, false)}
                editing={true}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'phone', 'cell_nr', cellNr, setCellNrErr, businessDetailsValidation)
                    BusinessDetailsUpdate('cell_nr', cellNr)
                  }
                }
                onChange={(event:any) => setCellNr(event)}
                errors={cellNrErr}
                value={String(cellNr)}
                maxLength={10}
                id={'cell_nr'}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('fax_nr', businessDetailsValidation, false)}
                onBlur={(e:any) => {
                  validateModelFields(e, 'phone', 'fax_nr', faxNr, setFaxNrErr, businessDetailsValidation)
                  BusinessDetailsUpdate('fax_nr', faxNr)
                  }
                }
                onChange={(event) => setFaxNr(event)}
                errors={faxNrErr}
                value={faxNr}
                maxLength={10}
                id={'fax_nr'}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('email_debit_order', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'email', 'email_debit_order', emailDebitOrder, setEmailDebitOrderErr, businessDetailsValidation)
                    BusinessDetailsUpdate('email_debit_order', emailDebitOrder)
                  }  
                }
                onChange={(event) => setEmailDebitOrder(event)}
                errors={emailDebitOrderErr}
                value={emailDebitOrder}
                id={'email_debit_order'}
              />
            </div>

            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('email_newsletter', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'email', 'email_newsletter', emailNewsletter, setEmailsNewsletterErr, businessDetailsValidation)
                    BusinessDetailsUpdate('email_newsletter', emailNewsletter)
                  }  
                }
                onChange={(event) => setEmailsNewsletter(event)}
                errors={emailNewsletterErr}
                value={emailNewsletter}
                id={'email_newsletter'}
              />
            </div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('email_practice_manager', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'email', 'email_practice_manager', emailPracticeManager, setEmailPracticeManagerErr, businessDetailsValidation)
                    BusinessDetailsUpdate('email_practice_manager', emailPracticeManager)
                  }
                }
                onChange={(event) => setEmailPracticeManager(event)}
                errors={emailPracticeManagerErr}
                value={emailPracticeManager}
                id={'email_practice_manager'}
              />
            </div>
            
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('email_accounts', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'email', 'email_accounts', emailAccounts, setEmailAccountsErr, businessDetailsValidation)
                    BusinessDetailsUpdate('email_accounts', emailAccounts)
                  }
                }
                onChange={(event) => setEmailAccounts(event)}
                errors={emailAccountsErr}
                value={emailAccounts}
                id={'email_accounts'}
              />
            </div>
          </div>
        </div>
        <div className={responsive.OverviewPanel}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Information Officer Details</h2>
          <div>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('credit_control_name', businessDetailsValidation, false)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'credit_control_name', creditControlName, setCreditControlNameErr, businessDetailsValidation)
                  BusinessDetailsUpdate('credit_control_name', creditControlName)
                }
              }
              onChange={(event) => setCreditControlName(event)}
              errors={creditControlNameErr}
              value={creditControlName}
              id={'credit_control_name'}
            />
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                name={getFieldLabel('io_cell_nr', businessDetailsValidation, false)}
                editing={true}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'number', 'io_cell_nr', IOCellNo, setIOCellNoErr, businessDetailsValidation)
                    BusinessDetailsUpdate('io_cell_nr', IOCellNo)
                  }
                }
                onChange={(event:any) => setIOCellNo(event)}
                errors={IOCellNoErr}
                value={String(IOCellNo)}
                maxLength={10}
                id={'io_cell_nr'}
              />  
            </div>
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('credit_control_email', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'email', 'credit_control_email', creditControlEmail, setCreditControlEmailErr, businessDetailsValidation)
                    BusinessDetailsUpdate('credit_control_email', creditControlEmail)
                  }
                }
                onChange={(event) => setCreditControlEmail(event)}
                errors={creditControlEmailErr}
                value={creditControlEmail}
                id={'credit_control_email'}
              />
            </div>
          </div>
        </div>

        <div className={responsive.OverviewPanel}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Practice Details</h2>
          <div>
            <label>{getFieldLabel('discipline', businessDetailsValidation, false)}</label>
            <select
              id={'discipline'}
              className={disciplineErr ? 'Error' : ''}
              value={discipline}
              onBlur={(e:any) => validateModelFields(e, 'none', 'discipline', discipline, setDisciplineErr, businessDetailsValidation)}
              onChange={(e) => {
                setDiscipline(e.target.value)
                BusinessDetailsUpdate('discipline', discipline)
              }}>
              <option></option>
              {disciplineApi.map((item: any) => (
                <option key={item.name} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <br/>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('practice_nr', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'none', 'practice_nr', practiceNr, setPracticeNrErr, businessDetailsValidation)
                    BusinessDetailsUpdate('practice_nr', practiceNr)
                  }
                }
                onChange={(event) => setPracticeNr(event)}
                errors={practiceNrErr}
                value={practiceNr}
                maxLength={7}
                id={'practice_nr'}
              />
            </div>

            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('hpcsa_nr', businessDetailsValidation, false)}
                onBlur={
                  (e:any) => {
                    validateModelFields(e, 'none', 'hpcsa_nr', hpcsaNr, setHpcsaNrErr, businessDetailsValidation)
                    BusinessDetailsUpdate('hpcsa_nr', hpcsaNr)
                  }
                }
                onChange={(event) => setHpcsaNr(event)}
                errors={hpcsaNrErr}
                value={hpcsaNr}
                id={'hpcsa_nr'}
              />
            </div>
          </div>
          <div>
            <div className={responsive.FieldLayoutGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('mhg_username', businessDetailsValidation, false)}
                onBlur={(e:any) => {
                  validateModelFields(e, 'none', 'mhg_username', mhgUsername, setMHGUsernameErr, businessDetailsValidation)
                  BusinessDetailsUpdate('mhg_username', mhgUsername)
                  }
                }
                onChange={(event) => setMHGUsername(event)}
                errors={mhgUsernameErr}
                value={mhgUsername}
                id={'mhg_username'}
              />
            </div>
            <div className={responsive.FieldLayoutEndGrid}>
              <TextInputValidation
                editing={true}
                name={getFieldLabel('mhg_password', businessDetailsValidation, false)}
                onBlur={(e:any) => {
                  validateModelFields(e, 'none', 'mhg_password', mhgPassword, setMHGPasswordErr, businessDetailsValidation)
                  BusinessDetailsUpdate('mhg_password', mhgPassword)
                  }
                }
                onChange={(event) => setMHGPassword(event)}
                errors={mhgPasswordErr}
                value={mhgPassword}
                id={'mhg_password'}
              />
            </div>
          </div>
        </div>

        <div className={responsive.OverviewPanel}>
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Upload Documents</h2>
          <div>
            <InputUpload
              name={getFieldLabel('id_document', businessDetailsValidation, false)}
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('id_document', idDocument)}
              errors={fileLT2MBValidator(idDocument) || idDocumentErr ? [fileLT2MBValidator(idDocument), idDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setIdDocument(event)
                BusinessDetailsUpdate('id_document', event)
              }}
              className={responsive.InputBox}
              id={'id_document'}
            />
            <InputUpload
              name={getFieldLabel('company_document', businessDetailsValidation, false)}
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('company_document', companyDocument)}
              errors={fileLT2MBValidator(companyDocument) || companyDocumentErr ? [fileLT2MBValidator(companyDocument), companyDocumentErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setCompanyDocument(event)
                BusinessDetailsUpdate('company_document', event)
              }}
              id={'company_document'}
            />
            <InputUpload
              name={getFieldLabel('banking_details', businessDetailsValidation, false)}
              accept="image/*, application/pdf"
              onBlur={() => BusinessDetailsUpdate('banking_details', bankDetails)}
              errors={fileLT2MBValidator(bankDetails) || bankDetailsErr ? [fileLT2MBValidator(bankDetails), bankDetailsErr].filter(Boolean) : null}
              onChange={(event: any) => {
                setBankDetails(event)
                BusinessDetailsUpdate('banking_details', event)
              }}
              id={'banking_details'}
            />
          </div>          
        </div>
        
        {quoteConfig?.debit_order === true ?
          <div className={responsive.OverviewPanel}>
          <>
          
          <h2 className={[bstyles.FieldHeader, bstyles.FieldHeaderMargin].join(' ')}>Banking Details</h2>
          <div className={responsive.OverviewRow}>
            <TextInputValidation
              editing={true}
              name={getFieldLabel('debit_order_account_name', businessDetailsValidation, false)}
              onChange={(event) => setAccountName(event)}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'debit_order_account_name', accountName, setAccountNameError, businessDetailsValidation)
                  BusinessDetailsUpdate('debit_order_account_name', accountName)
                }
              }
              errors={accountNameError}
              value={accountName}
              id={'debit_order_account_name'}
            />
            
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('debit_order_bank', businessDetailsValidation, false)}
                  onChange={(event) => setBank(event)}
                  onBlur={
                    (e:any) => { 
                      validateModelFields(e, 'none', 'debit_order_bank', bank, setBankError, businessDetailsValidation)
                      BusinessDetailsUpdate('debit_order_bank', bank)
                    }
                  }
                  errors={bankError}
                  value={bank}
                  id={'debit_order_bank'}
                />
              </div>

              <div className={responsive.FieldLayoutEndGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('debit_order_account_no', businessDetailsValidation, false)}
                  onChange={(event) => setAccountNo(event)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'debit_order_account_no', accountNo, setAccountNoError, businessDetailsValidation)
                      BusinessDetailsUpdate('debit_order_account_no', accountNo)
                    }
                  }
                  errors={accountNoError}
                  value={accountNo}
                  id={'debit_order_account_no'}
                />
              </div>
            </div>
            
            <div>
              <div className={responsive.FieldLayoutGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('debit_order_branch', businessDetailsValidation, false)}
                  onChange={(event) => setBranch(event)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'debit_order_branch', branch, setBankBranchError, businessDetailsValidation)
                      BusinessDetailsUpdate('debit_order_branch', branch)
                    }
                  }
                  errors={bankBranchError}
                  value={branch}
                  id={'debit_order_branch'}
                />
              </div>
              <div className={responsive.FieldLayoutEndGrid}>
                <TextInputValidation
                  editing={true}
                  name={getFieldLabel('debit_order_branch_code', businessDetailsValidation, false)}
                  onChange={(event) => setBranchCode(event)}
                  onBlur={
                    (e:any) => {
                      validateModelFields(e, 'none', 'debit_order_branch_code', branchCode, setBranchCodeError, businessDetailsValidation)
                      BusinessDetailsUpdate('debit_order_branch_code', branchCode)
                    }
                  }
                  errors={branchCodeError}
                  value={branchCode}
                  id={'debit_order_branch_code'}
                />
              </div>
            </div>
            <div>
              <div className={responsive.FieldAccountType}>
                <label>Account Type:</label>
                    <select
                    id={'debit_order_account_type'}
                    className={accountTypeError ? 'Error' : ''}
                    value={accountType}
                    name={getFieldLabel('debit_order_account_type', businessDetailsValidation, false)}
                    onBlur={
                      (e:any) => {
                        validateModelFields(e, 'none', 'debit_order_account_type', accountType, setAccountTypeError, businessDetailsValidation)
                        BusinessDetailsUpdate('debit_order_account_type', accountType)
                      }
                    }
                    onChange={(event) => setAccountType(event.target.value)}>
                        <option> </option>
                        <option key={1} value="Current(Cheque)">Current(Cheque)</option>
                        <option key={2} value="Savings">Savings</option>
                        <option key={3} value="transmission">transmission</option>
                </select>
              </div>
              <div className={responsive.FieldDebitOrderDate}>
                <label>{getFieldLabel('date_of_debit_order', businessDetailsValidation, false)}</label>
                    <select
                    id={'date_of_debit_order'}
                    className={dateOfDebitOrderError ? 'Error' : ''}
                    value={dateOfDebitOrder}
                    name={getFieldLabel('date_of_debit_order', businessDetailsValidation, false)}
                    onBlur={
                      (e:any) => {
                        validateModelFields(e, 'none', 'date_of_debit_order', dateOfDebitOrder, setDateOfDebitOrderError, businessDetailsValidation)
                        BusinessDetailsUpdate('date_of_debit_order', dateOfDebitOrder)
                      }
                    }
                    onChange={(event) => setDateOfDebitOrder(event.target.value)}>
                        <option> </option>
                        <option key={1} value="3">3</option>
                        <option key={2} value="7">7</option>
                        <option key={3} value="15">15</option>
                </select>
              </div>
            </div>
            </div>
          </>
          </div>
        : null
        }
        <>
        { isTokenValid.isTemp ?
        <></>:
        
        <div className={responsive.OverviewPanel}>
        <>
        <div className={responsive.OverviewRow}>
          <label>Heard From: *</label>
          <Select
            id={'heard_from'}
            className={styles.multiValue}
            isMulti={true}
            onBlur={(e) => validateModelFields(e, 'none', 'heard_from', heardFrom, setHeardFromErr, businessDetailsValidation)}
            onChange={(selectedOptions) => {
              if (selectedOptions) {
                setHeardFrom(selectedOptions.map(option => ({
                  label: option.label,
                  value: option.value
                })))
                BusinessDetailsUpdate('heardFrom', selectedOptions)
              } 
              else {
                setHeardFrom([]);
                BusinessDetailsUpdate('heardFrom', []);
              }
            }}
            options={[...HeardFromOptions]}
            value={heardFrom || []}
          />
          
          <label>{getFieldLabel('seen_social_media', businessDetailsValidation, false)}</label>
          <select
            id={'seen_social_media'}
            className={disciplineErr ? 'Error' : ''}
            value={seenSocialMedia}
            onChange={(e) => setSeenSocialMedia(e.target.value)}
            onBlur={(e:any) => {
              validateModelFields(e, 'select', 'seen_social_media', seenSocialMedia, setSeenSocialMediaErr, businessDetailsValidation)
              BusinessDetailsUpdate('seen_social_media', seenSocialMedia)}
            }
            >
            <option></option>
            <option value='Yes'>Yes</option>
            <option value='No'>No</option>
          </select>
          <span className={styles.ErrorMsg}>{seenSocialMediaErr}</span>
        </div>
        <div className={responsive.TermsWrapper}>
          <p>
            I, <input className={responsive.ContractUndersigned}
              id={'accept_contact'}
              name="agreement_contact"
              placeholder={"Enter Full Name"}
              onBlur={
                (e:any) => {
                  validateModelFields(e, 'none', 'accept_contact', acceptContact, setAcceptContactErr, businessDetailsValidation)
                  BusinessDetailsUpdate('accept_contact', acceptContact)
                }
              }
              onChange={(event) => setAcceptContact(event.target.value)}
              value={acceptContact}
            />, confirm that the information supplied, is correct.</p>
            <span className={responsive.ErrorMsg}> {acceptContactErr}</span>
        </div>
        </>
        </div>
        }
        </>
        { isTokenValid.isTemp ?
        <>
        {isLoading ?
          <button
            className={responsive.SubmitButton}
            onClick={marketerSubmit}
            disabled={true}
          >
            Please wait ... quote is being processed 
          </button>
        : <button
          className={responsive.SubmitButton}
          onClick={marketerSubmit}
            >
          Save <span className="material-icons">arrow_forward</span>
        </button>
        }
        </>
        :
        <>
        {isLoading ?
         <div className={responsive.fixedFooter}>
            <button
              className={responsive.SubmitButton}
              onClick={businessSubmit}
              disabled={true}
            >
              Please wait ... quote is being processed 
            </button>
          </div>
        : 
        <div className={responsive.fixedFooter}>
          <button
            className={responsive.SubmitButton}
            onClick={businessSubmit}
          >
            Submit <span className="material-icons">arrow_forward</span>
          </button>
        </div>
        }
        </>
      }
        

        <ToastContainer />

        </form>
      </div>
      </MobileLayout>
      </>
      :
      <div className={styles.FormWrapper}>
        <div className={styles.QuoteCompleteCard}>
            <label className={styles.QuoteCompleteCardHeading}>Quote # {businessValues.quote_id}</label><br /><br />
            <label className={styles.QuoteCompleteCardHeading}>Your quotation has already been accepted.</label>
            <br/>
        </div>
      </div>
    }
    </>
  
  )
};

export default BusinessDetails;
